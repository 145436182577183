<div [ngClass]="['full-width flex-column-start-center', overallPadding]">

  <ng-content select="[sticky-top]"></ng-content>

  <a7-loading-content [progressor]="progressor"
                      class="full-width v2-max-w-102">
    <a7-loading-logo class="v2-w-3 v2-h-3"
                     a7-loading-indicator>
    </a7-loading-logo>

    <div [ngClass]="['full-width']">
      <ng-content select="[top]"></ng-content>
    </div>

    <div [ngClass]="[
        mobile ? mobileLayout : webLayout
      ]">
      <div [ngClass]="['full-width v2-max-w-69 v2-padding-1-b']">
        <ng-content select="[left]"></ng-content>
      </div>

      <div *ngIf="!(mobile && hideRightOnMobile)"
           [ngClass]="[
          'full-width',
          mobile ? 'v2-max-w-57' : ('v2-max-w-30 ' + (windowHeight >= 940 ? ('sticky ' + topStickyClass) : '')),
          rightClasses
        ]">
        <ng-content select="[right]"></ng-content>
      </div>
    </div>
  </a7-loading-content>
</div>
