<div [ngClass]="[
    'full-width flex-column-start-center',
    xs ? 'mobile-typography' : 'homepage-typography'
  ]">
  <div class="full-width flex-column-start-center">
    <div [ngClass]="[
        'full-width v2-padding-4-h flex-column-start-center relative',
        mobile ? 'top-bg-mobile' : ''
      ]"
         style="overflow-x:hidden;">
      <div class="header-background absolute v2-left-0 v2-top-0 full-width v2-h-44 z-index-low bg-primary-600"></div>
      <div class="v2-max-w-113 z-index-low absolute full-width v2-h-44">
        <img class="absolute v2-w-20 decorator-ring-top"
             src="assets/app-header-decorator-1.webp" />
        <img class="absolute v2-w-17 decorator-ring-bottom"
             src="assets/app-header-decorator-0.webp" />
      </div>
      <div class="full-width v2-margin-2-t">
        <span class="text-white-high-emphasis h3">
          <span class="icon-fullsize-logo-weight-3 home-feature-title"></span>
        </span>
      </div>

      <div [ngClass]="[
          'flex-wrap full-width v2-max-w-102 text-white-high-emphasis ',
          mobile
            ? 'flex-column-start-center v2-row-gap-3 v2-margin-5-t'
            : 'flex-row-between-start v2-margin-5-t'
        ]">
        <div [ngClass]="[
            mobile
              ? ' flex-column-start-center text-center v2-max-w-42'
              : 'v2-margin-32px-t flex-column-start-start v2-max-w-38 v2-max-w-65pct',
            ''
          ]">
          <h1 class="display-1 v2-margin-2-b">{{ heroMessage }}</h1>
          <ng-container [ngTemplateOutlet]="downloadButton"
                        [ngTemplateOutletContext]="{ inverse: true }"></ng-container>
          <img src="assets/app-rating.svg"
               class="v2-margin-32px-t"
               alt="app rating" />
        </div>
        <div class="relative v2-max-80vw v2-max-h-44">
          <img class="v2-max-80vw v2-max-h-44"
               src="/assets/app-iphone.webp"
               alt="phone photos" />
          <div class="absolute stats-labels">
            <div
                 class="stats-label full-width v2-padding-16px-v color-primary-700 bg-white-80 v2-radius-20px text-center">
              <h2>$2,500,000+</h2>
              <div class="footer-2">Dividends paid so far</div>
            </div>
            <div
                 class="stats-label full-width v2-padding-16px-v color-primary-700 bg-white-80 v2-margin-16px-t v2-radius-20px text-center">
              <h2>$20,000,000+</h2>
              <div class="footer-2">Property value funded across 10 states</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngFor="
        let section of sections;
        let i = index
      ">
      <div [ngClass]="[
          'full-width  center-inner  v2-padding-4-h',
          i % 2 === 0 ? '' : ' bg-blue-grey-1'
        ]"
           [ngStyle]="{
          'padding-top': (mobile ? 80 : section.padding) + 'px',
          'padding-bottom': (mobile ? 80 : section.padding) + 'px'
        }">
        <div [ngClass]="[
            'flex-wrap v2-max-w-94 full-width',
            mobile
              ? 'flex-column-reverse-between-center v2-row-gap-4'
              : i % 2 === 0
              ? 'flex-row-between-center'
              : 'flex-row-reverse-between-center'
          ]">
          <img [src]="section.image"
               [ngClass]="['v2-max-100vw']"
               [style.max-height.px]="section.height"
               [alt]="section.title" />
          <div [ngClass]="[
              mobile
                ? 'flex-column-start-center v2-max-w-42'
                : 'flex-column-start-start v2-max-w-30'
            ]">
            <div>
              <div class="h1 z-index-high relative">
                {{ section.title }}
              </div>
              <img [ngStyle]="{
                  height: '18px',
                  transform: 'translate(0px, -28px)'
                }"
                   src="/assets/mobile/stripe.svg"
                   alt="title decorator" />
            </div>
            <div [ngClass]="[
                'color-green-grey-2 v2-margin-2-t-n',
                mobile ? 'text-center' : ''
              ]">
              {{ section.desc }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div [ngClass]="[
        'full-width flex-column-center-center text-center v2-padding-12-b end-bg v2-padding-2-h',
        mobile ? ' v2-padding-5-t ' : 'v2-padding-1-t'
      ]">
      <h2>Have your portfolio in control</h2>
      <div class="color-green-grey-2 v2-margin-32px-b">
        Now available on both iOS and Android
      </div>
      <ng-container [ngTemplateOutlet]="downloadButton"></ng-container>
    </div>
  </div>

  <v2-page-footer class="full-width"
                  [topPadding]="mobile ? 'v2-padding-5-t' : 'v2-padding-10-t'"></v2-page-footer>
</div>

<ng-template #downloadButton
             let-inverse="inverse">
  <div class="flex-row-center-center v2-col-gap-1">
    <a [routerLink]="['/download-link']"
       [queryParams]="downloadQueryParams">
      <div [ngClass]="[
        inverse ? 'bg-white' : 'bg-blue-grey-9',
        'v2-radius-8px flex-row-center-center v2-col-gap-1 pointer'
      ]">
        <img class="max-width-100"
             [src]="inverse ? 'assets/app-store-btn.svg' : 'assets/app-store-btn-dark.svg'"
             alt="app store" />
      </div>
    </a>

    <a [routerLink]="['/download-link']"
       [queryParams]="downloadQueryParams">
      <div [ngClass]="[
     inverse ? 'bg-white' : 'bg-blue-grey-9',
     'v2-radius-8px flex-row-center-center v2-col-gap-1 pointer'
   ]">
        <img class="max-width-100"
             [src]="inverse ? 'assets/google-play-btn.svg' : 'assets/google-play-btn-dark.svg'"
             alt="google play" />
      </div>
    </a>
  </div>
</ng-template>
