<app-investment-location-pois *ngIf="googleMapEnabled"
                              class="v2-margin-2-t v2-h-33"
                              [carrier]="carrier"
                              [mobile]="md">
</app-investment-location-pois>
<div class="flex-row-start-center h5 v2-margin-1-t"
     *ngIf="showMap">
  <img src="/assets/v2-map-loc.svg" />
  {{ carrier.address.fullAddress }}
</div>

<div class="v2-margin-16px-t color-green-grey-2 flex-column-between-start v2-row-gap-8px">
  <div *ngFor="let p of ((carrier.introduction?.marketIntroduction?.summary?.split('\n') ?? []))"
       [innerHtml]="replaceHighlight(p, 'h4')"></div>
</div>

<div class="v2-margin-32px-t full-width">
  <div class="flex-column-between-start v2-row-gap-2">
    <ng-container *ngFor="
        let fi of [
          [
            'salePricePerSqft',
            'salePricePerSqftGrowthRate',
            'Average Sale Price per Square Foot',
            'Measures the unit sale price per square foot of a typical home similar to the listed property. It is a useful metric because it is helpful in determining whether a home is reasonably priced compared to the average benchmark. It is also often used to compare price across markets.',
            'Percentage Change from Same Time Last Year',
            true
          ],
          [
            'daysOnTheMarket',
            'daysOnTheMarketChange',
            'Median Days on Market',
            'Measures how long it takes for a home to sell after it has been put on the market. It is an important metric because it indicates whether it is currently a market that favors the buyers or sellers.',
            'Number of Change from Same Time Last Year',
            false
          ],
          [
            'soldProperties',
            'soldPropertyGrowthRate',
            'Number of Homes Sold',
            'Measures how many homes were sold in a given time period. It is a useful indicator to monitor the overall transaction level between buyers and sellers.',
            'Percentage Change from Same Time Last Year',
            true
          ]
        ]
      ">
      <ng-container *ngTemplateOutlet="
          marketKey;
          context: {
            field: fi[0],
            rate: fi[1],
            label: fi[2],
            tip1: fi[3],
            tip2: fi[4],
            percentChange: fi[5]
          }
        ">
      </ng-container>
    </ng-container>
  </div>

  <a7-carrier-captions [gray]="[
      'Data source: Redfin',
      carrier.introduction.marketIntroduction.dataFilters
    ]">
  </a7-carrier-captions>
</div>


<ng-template #marketKey
             let-field="field"
             let-rate="rate"
             let-label="label"
             let-tip1="tip1"
             let-tip2="tip2"
             let-percentChange="percentChange">
  <div class="full-width flex-row-between-center"
       *ngIf="carrier.introduction.marketIntroduction[field]">
    <img [src]="'/assets/tplus/' + field + '.svg'" />
    <span class="color-green-grey-2 h5 v2-margin-16px-l">{{ label }}

      <span *ngIf="tip1"
            [cTip]="tip1"
            class="v2-margin-8px-l">
        <fa-icon [icon]="['far', 'question-circle']"> </fa-icon>
      </span>
    </span>

    <span class="flex-scale"></span>
    <span class="color-green-grey-1 h4">
      {{ carrier.introduction.marketIntroduction[field] }}
    </span>

    <span class="color-green-grey-1 h4 v2-w-7 text-right v2-margin-5-l">
      <span *ngIf="carrier.introduction.marketIntroduction[rate] > 0"
            class="v2-margin-4px-r-n">+</span>
      <span *ngIf="percentChange">
        {{
          carrier.introduction.marketIntroduction[rate] | percent: '.0-0'
        }}</span>
      <span *ngIf="!percentChange">
        {{ carrier.introduction.marketIntroduction[rate] }}</span>
      <span *ngIf="tip2"
            [cTip]="tip2"
            class="v2-margin-8px-l">
        <fa-icon [icon]="['far', 'question-circle']"> </fa-icon>
      </span>
    </span>

    <img *ngIf="percentChange; else emptyImg"
         class="v2-margin-8px-l"
         [src]="
        carrier.introduction.marketIntroduction[rate] > 0
          ? '/assets/v2-up.svg'
          : '/assets/v2-down.svg'
      " />

    <ng-template #emptyImg>
      <div class="v2-w-3"></div>
    </ng-template>
  </div>
</ng-template>
