import moment from 'moment';
import {
  APPLICATION_META,
  ApplicationMeta,
  observableToPromise,
} from '@ark7/utils';
import { ActivatedRoute } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { ReplaySubject } from 'rxjs';
import { withInheritedProps as dotty } from 'object-path';
import { formatCurrency, formatDate } from '@angular/common';

import { AppConfigurationResource } from '../resources/app-configuration-resource';

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  // We can not use tracking service due to circular deps.
  @LocalStorage() trackingCode: string;

  _configs: { [key: string]: any } = undefined;

  // Changes for event subscription
  configChange$ = new ReplaySubject<any>(1);

  constructor(
    @Inject(APPLICATION_META) private applicationMeta: ApplicationMeta,
    private configurationResource: AppConfigurationResource,
    activeRoute: ActivatedRoute,
  ) {
    activeRoute.queryParams.subscribe(async (params) => {
      if (params.tc) {
        this.trackingCode = params.tc;
      }

      await this.fetch();
      this.configChange$.next(this._configs);
    });
  }

  async fetch() {
    if (this._configs) {
      return;
    }
    if (
      typeof window !== 'undefined' &&
      window?.location.hostname &&
      !window?.location.hostname.includes('renters') &&
      !window?.location.hostname.includes('ark7-corp-allosaurus') &&
      window?.location.port !== '4205' &&
      !window?.location.hostname.includes('admin.') &&
      window?.location.port !== '4203'
    ) {
      this._configs = await observableToPromise(
        this.configurationResource.query({
          osType: 'WEB',
          appVersion: this.applicationMeta.version,
          trackingCode: this.trackingCode ?? undefined,
        }),
      );

      // OVERRIDE BELOW FOR TESTING
      // dotty.set(this._configs, 'app.returnCalculator.enabled', true);
      // dotty.set(this._configs, 'app.returnCalculator.model', 'simple');
      // dotty.set(this._configs, 'app.featuredCarrier', {
      //   carrierId: '5f17114f5c096b002db13ea5',
      //   title: 'Invest in Dallas for Stable Returns',
      //   subtitle: 'Earn prorated rent of $3,300/mo by co-owning Dallas-S9',
      //   tag: 'SPECIAL',
      //   recommendationReason:
      //     'Located just 20 minutes east of downtown Dallas, Dallas-S9 haveg full access to the economic and cultural hub of North Texas, the most populous, diverse, and fastest growing (18.8% v.s. 7.4% US average) metropolitan area in the Southern United States , and yet enjoy the family-friendly community with beautiful scenery and more affordable living style.',
      //   cardImage:
      //     'https://resources.ark7.com/production/images/cnkkjja76d0qeuvk8wrp6cada0vkchaj6xw6cduh698ku.jpg',
      //   detailPageImage:
      //     'https://resources.ark7.com/production/images/drt44uj56hm76y3p89t3cda6d574pmj5e1k7gvvjb9vku.jpg',
      //   cardImageWidth: 2048,
      //   cardImageHeight: 1366,
      // });
      // dotty.set(this._configs, 'app.home.investedUserModulesRight', [
      //   ModuleName.accountSummary,
      //   ModuleName.dividendDayBonus,
      //   ModuleName.referralBonus,
      //   ModuleName.milestoneBonus,
      //   ModuleName.welcomeBonus,
      //   ModuleName.myPortfolio,
      // ]);
    }
  }

  get isConfigLoaded(): boolean {
    return this._configs != null;
  }

  getConfig(key: string): any {
    return dotty.get(this._configs ?? {}, key);
  }

  get showStickyReferralBar(): boolean {
    return this.getConfig('app.referral.cardStyle') === 'sticky';
  }

  get referralTrackingCode(): string {
    return this.getConfig('app.referral.trackingCode') ?? 'DRHIA';
  }

  get referralEndDate(): Date {
    return new Date(this.getConfig('app.referral.endDate'));
  }

  get referralShareMessage(): string {
    return (
      this.getConfig('app.l10n.referralsShareText') ??
      "Here's a way to invest in real estate on your terms. Sign up for Ark7 and we can each earn $#REFEREE_BONUS_AMOUNT#. T&Cs apply."
    );
  }

  get referralProgramTitle(): string {
    return (
      this.getConfig('app.l10n.referralsBonusNormalTitle') ??
      '#REFERRER_BONUS_AMOUNT# for you, #REFEREE_BONUS_AMOUNT# for your friend!'
    );
  }

  get referralProgramDesc(): string {
    return (
      this.getConfig('app.l10n.referralsBonusNormalDesc') ??
      'Helping others get into real estate investing has never been this simple. Invite your friends to explore how ' +
        'Ark7 works. You’ll each receive Ark7 bonus credits when your friend invests <subheading2>#MIN_INVESTMENT_AMOUNT#</subheading2> in property shares.'
    );
  }

  get referralInvitationDesc(): string {
    return (
      this.getConfig('app.l10n.referralsInvitationDesc') ??
      "Join your friend on Ark7 and get started to earn consistent monthly rental income. Plus, you'll receive a <subheading2>#REFEREE_BONUS_AMOUNT#</subheading2> New Investor Bonus when you invest " +
        '<subheading2>#MIN_INVESTMENT_AMOUNT#</subheading2> in property shares.'
    );
  }

  get referralInvitationResultDesc(): string {
    return (
      this.getConfig('app.l10n.referralsInvitationResultDesc') ??
      'Modern real estate investing is at your fingertip. Download the Ark7 app today to claim your new investor bonus of <subheading2>#REFEREE_BONUS_AMOUNT#</subheading2> when you invest <subheading2>#MIN_INVESTMENT_AMOUNT#</subheading2> in property shares.'
    );
  }

  get referralCardDesc2(): string {
    return (
      this.getConfig('app.l10n.referralBonusHomeWidgetInvestAmount') ??
      'When your friend invests #MIN_INVESTMENT_AMOUNT#.'
    );
  }

  get referrerBonusAmount() {
    const amount = this.getConfig('app.referral.referrerBonusAmount');
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get refereeBonusAmount() {
    const amount = this.getConfig('app.referral.refereeBonusAmount');
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get referrerBonusValue() {
    return this.getConfig('app.referral.referrerBonusAmount') ?? 0;
  }

  get referrerExtraBonusValue() {
    return this.getConfig('app.referral.referrerExtraBonusAmount') ?? 0;
  }

  get referralExtraBonusInvestmentValue() {
    return this.getConfig('app.referral.extraBonusInvestmentAmount');
  }

  get referralMinInvestmentAmount() {
    const amount = this.getConfig('app.referral.minInvestmentAmount');
    return amount == null
      ? ''
      : formatCurrency(amount, 'en-US', '$', 'USD', '.0');
  }

  get landingHeroTitle(): string {
    return (
      this.getConfig('web.landing.heroTitle') ??
      'Invest in rental homes - share by share.'
    );
  }

  get landingHeroSubtitle(): string {
    return (
      this.getConfig('web.landing.heroSubtitle') ??
      'Ark7 brings high-yield rental home investment to you. We believe everyone should have the freedom to build wealth through real estate.'
    );
  }

  get landingShowAppSection(): boolean {
    return this.getConfig('web.landing.showAppSection') ?? true;
  }

  get landingHeroImage(): string {
    return (
      this.getConfig('web.landing.heroImage') ?? '/assets/landing_hero_s11.webp'
    );
  }

  get landingHeroSectionClass(): string {
    return (
      this.getConfig('web.landing.heroSectionClass') ?? 'clear-padding-right'
    );
  }

  get landingHeroImageClass(): string {
    return (
      this.getConfig('web.landing.heroImageClass') ?? 'v2-max-w-60 v2-max-h-53'
    );
  }

  get landingHeroCtaType(): string {
    return this.getConfig('web.landing.heroCtaType') ?? 'EMAIL_INPUT';
  }

  get landingHeroCtaText(): string {
    return this.getConfig('web.landing.heroCtaText') ?? 'Browse Properties';
  }

  get popupAppDownloadOnSignUp(): boolean {
    return this.getConfig('web.signUp.popupAppDownload') ?? false;
  }

  get popupAppDownloadOnLogin(): boolean {
    return this.getConfig('web.login.popupAppDownload') ?? false;
  }

  get impactVerifyEmail(): boolean {
    return this.getConfig('web.tc.verifyEmail') ?? true;
  }

  get impactValidateProfile(): boolean {
    return this.getConfig('web.tc.validateProfile') ?? false;
  }

  get impactSamplingRate(): number {
    return this.getConfig('web.tc.isr') ?? 1.0;
  }

  get investorProfileQuestionnaireEnabled(): boolean {
    return (
      this.getConfig('app.user.investorProfileQuestionnaireEnabled') ?? true
    );
  }

  get minInvestmentAmount(): number {
    return this.getConfig('app.orders.minInvestmentAmount') ?? 0;
  }

  get announcementMessage(): string {
    return this.getConfig('app.home.announcement.message');
  }

  get announcementVideoUrl(): string {
    return this.getConfig('app.home.announcement.videoUrl');
  }

  get announcementVideoCtaText(): string {
    return this.getConfig('app.home.announcement.videoCtaText');
  }

  get welcomeBonusInfo() {
    return this.getConfig('app.home.welcomeBonus');
  }

  get opportunityCard() {
    return this.getConfig('app.home.opportunityCard');
  }

  get hasDividendBoost() {
    return (
      (this.welcomeBonusInfo?.dividendBoostRate ??
        this.opportunityCard?.dividendBoostRate) > 0
    );
  }

  get secondaryMarketDividendRateTooltip() {
    return 'Annualized cash return based on last traded price, using the average dividend distribution over the past 12 months.';
  }

  get dividendRateWithBoostTooltip() {
    return this.hasDividendBoost
      ? 'Annualized cash return rate for the past 12 months with dividend boost. For newer listed properties with data less than 12 months, the IPO to date average or estimated rate is used.'
      : 'Annualized cash return rate for the past 12 months. For newer listed properties with data less than 12 months, the IPO to date average or estimated rate is used.';
  }

  get dividendPerShareWithBoostTooltip() {
    return this.hasDividendBoost
      ? 'Monthly cash distribution per share for the past 12 months with dividend boost. For newer listed properties with data less than 12 months, IPO to date average or estimated number is used.'
      : 'Monthly cash distribution per share for the past 12 months. For newer listed properties with data less than 12 months, IPO to date average or estimated number is used.';
  }

  get dividendBoostEndDate() {
    return formatDate(
      new Date(this.welcomeBonusInfo?.endDate ?? this.opportunityCard?.endDate),
      'mediumDate',
      'en-US',
    );
  }

  get dividendBoostRate() {
    return (
      this.welcomeBonusInfo?.dividendBoostRate ??
      this.opportunityCard?.dividendBoostRate ??
      0
    );
  }

  get dividendBoostDuration() {
    return (
      moment
        .duration(
          this.welcomeBonusInfo?.dividendBoostDuration ??
            this.opportunityCard?.dividendBoostDuration,
        )
        .asMonths() + ' months'
    );
  }
}
