import { A7PageSizeMatcher } from '@ark7/layout';
import { AfterViewInit, Component, Input } from '@angular/core';
import { replaceHighlight } from '@ark7/utils';

import { CarrierBaseComponent } from '../carrier-base/carrier-base.component';

@Component({
  selector: 'a7-carrier-market',
  templateUrl: './carrier-market.component.html',
  styleUrls: ['./carrier-market.component.scss'],
})
export class CarrierMarketComponent
  extends CarrierBaseComponent
  implements AfterViewInit
{
  @A7PageSizeMatcher('MD-') md;

  @Input() showMap = true;

  replaceHighlight = replaceHighlight;

  googleMapEnabled = false;
  ngAfterViewInit() {
    this.googleMapEnabled =
      this.showMap && (typeof google !== 'undefined' ? true : false);
  }
}
